import DataTable from "react-data-table-component";
import { MDBIcon } from 'mdb-react-ui-kit';

const customStyles = {
  rows: {
      style: {
          maxHeight: '35px', // override the row height
      },
  },
  headCells: {
      style: {
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
      },
  },
  cells: {
      style: {
          paddingLeft: '8px', // override the cell padding for data cells
          paddingRight: '8px',
      },
  },
};

interface IProps {
  keyDataTable: string;
  // headers: any[];
  columns: any[];
  data: any[];
  sortFieldId?: number;
  sortAsc?: boolean;
}

const DataTableCustom = ({keyDataTable, columns, data, sortFieldId=0, sortAsc=true }: IProps) => {
  return (
    <>
        <DataTable
          style={{ minWidth: '800px', width: '100%' }}
          columns={ columns }
          data={data}
          defaultSortFieldId={sortFieldId}
          sortIcon={<MDBIcon  icon='long-arrow-alt-up'  />}
          defaultSortAsc={sortAsc}
          pagination
          selectableRows={false}
          highlightOnHover
          progressPending={false}
          customStyles={customStyles}
          paginationComponentOptions={{
            rowsPerPageText: 'Filas por página:',
            rangeSeparatorText: 'de',
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'Todos',
          }}
          // dense
        />
    
    {/* <MDBTable>
      <MDBTableHead key={keyDataTable}>
        <tr>
          { headers.map(({label, name}) => {
            return <th key={`${keyDataTable}-th-${name}`} scope='col'>{ label }</th>
          }) }
          <th></th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {
          data.map((item, index) => {
            return (
              <tr key={`${keyDataTable}-tr-${index}`} >
                { headers.map(({name, customItem}) => {
                  return <StyleTd key={`${keyDataTable}-td-${name}`} >{customItem ? customItem(item) : item[name] }</StyleTd>
                })}
                <td>{ actionsDataTable(item) }</td>
              </tr>
            )
          })
        }
      </MDBTableBody>
    </MDBTable> */}
    </>
  );
}

export default DataTableCustom

import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useSettingsStore } from "../../store/settingsStore";
import { shallow } from "zustand/shallow";
import { formatMoney } from "../general/utils";

type StyledProps = {
    isColumnVisible: boolean;
    // buttonRight: string;
  }

const StyledButton = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background: #007BFF;
  color: #fff;
  padding: 34px 2px;
  cursor: pointer;
  transition: right 0.3s ease-in-out;

  &:hover {
    background: #0056b3;
  }
`;

const StyledColumn = styled.div<StyledProps>`
  border: 1px solid #ccc;
  margin-right: 0px;
  padding: 12px;
  background-color: ${({ isColumnVisible }) => (isColumnVisible ? 'white' : 'lightsteelblue')};
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  position: relative;
  min-width: ${({ isColumnVisible }) => (isColumnVisible ? '260px' : '0px')};
`;

const StyledContent = styled.div<StyledProps>`
  display: ${({ isColumnVisible }) => (isColumnVisible ? 'block' : 'none')};
`

const BancosSaldos = () => {
    const bancosServer = useSettingsStore((state) => (state.bancos), shallow)
    // const [loading, setLoading] = useState(true)
    const [bancos, setBancos] = useState<any[]>([])
    const [isColumnVisible, setIsColumnVisible] = useState(true);
    let totalSaldo = {dlls: 0, mx: 0};

    useEffect(() => {
      // setLoading(false)
      setBancos(bancosServer)
      }, [bancosServer])

    return (
    <StyledColumn className="md-3" isColumnVisible={isColumnVisible} >
      <StyledContent isColumnVisible={isColumnVisible}>
        <h4>Saldo en</h4>
        <h4>Bancos</h4>
        <hr />

        { bancos.map(({ name, saldoInicial, ingresos, egresos }) => {
          const keySaldo = name.includes('Dlls') ? 'dlls' : 'mx';
          const saldo = Number(saldoInicial) + Number(ingresos) - Number(egresos)
          totalSaldo[keySaldo] = totalSaldo[keySaldo] + saldo;
          return (
            <div className="d-flex flex-row mb-2 justify-content-between">
              <div className="p-2 fw-bold" style={{maxWidth: "50%", textAlign: "start"}} >+ { name }</div>
              <div className="p-2">{ formatMoney(saldo) }</div>
            </div>
          )
        }) }
        <hr />
        <h6 className="txt-align-left text-primary">Total Mx: {formatMoney(totalSaldo.mx)} </h6>
        <h6 className="txt-align-left text-primary">Total Dlls: {formatMoney(totalSaldo.dlls)}</h6>
        
      </StyledContent>

      <StyledButton onClick={() => setIsColumnVisible(!isColumnVisible)} >
        {isColumnVisible ? <BsChevronRight size={20} /> : <BsChevronLeft size={20} />}
      </StyledButton>
    </StyledColumn>
    )
}

export default BancosSaldos
